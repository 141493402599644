import React from "react"
import Contacts from "../../components/contacts"
import styled from "styled-components"
import Seo from "../../components/seo"
import Quote from "../../components/about-page/quote"
import Headquarters from "../../components/about-page/headquarters"
import Interested from "../../components/about-page/interested"
import ContactUs from "../../components/about-page/contact-us"
import HeaderLp from "../../components/landing-pages/headerLp"
import StickyCtaClosable from "../../components/landing-pages/stickyCtaClosable"
import { useContext } from "react"
import { GlobalAuthContext } from "../../Contexts/GlobalAuthContext"
import saleDate from "../../utils/saleDate"
const Style = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
`

function About({ location }) {
  const { closedSticky, setClosedSticky } = useContext(GlobalAuthContext)

  return (
    <>
      <Seo title="About us" noindex={true}></Seo>
      <Style>
        {closedSticky == false && (
          <StickyCtaClosable
            date={`Get 38% off for a limited time! Sale ends ${saleDate()}.`}
          ></StickyCtaClosable>
        )}
        <HeaderLp
          wrapLocation={location}
          closedSticky={closedSticky}
          location={`lp1`}
        />
        <Quote />
        <Headquarters />
        <Interested />
        <ContactUs />
      </Style>
    </>
  )
}

export default About
